import { deletePrescriptionMedication } from './../../state-prescription/+state/prescription.actions';
import { addLog } from '@medlogic/shared/state-log';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import {
  GlobalService, ICadastro,
  IInterventionMedication,
  ILog,
  ILogin,
  IMedication, IPatient, MsgPtBR
} from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { map, tap, toArray, withLatestFrom } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  IntervencoesMedicamentosService, InterventionMedicationCustomService,
  MedicationCheckinCustomService,
  PersonCustomService,
} from '@medlogic/medlogic/medlogic-data-access';
import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';
import {
  medicationFail, loadMedications,
  loadMedicationsSuccess, loadMedicationsBySelectedPatient,
  confirmMedication, upsertMedication, upsertMedications, addInterventionMedication, loadRawMedicationsSuccess, loadRawMedications,
  loadMedicationsByPrescription, rawMedicationFail, saveMedicationSuccess, checkMedicationIntervention, deleteMedication, updateMedication, loadHistoryMedications, loadHistoryMedicationsSuccess, historyMedicationFail, confirmAllMedications,
} from './medication.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { stockChange, stockChanges } from '../../state-stock/+state/stock.actions';
import { updatePrescription } from '../../state-prescription/+state/prescription.actions';
import { Dictionary, Update } from '@ngrx/entity';

import * as moment from 'moment';
@Injectable()

export class MedicationEffects {

  // private getMedication(medicationId: number, id: string, entities: Dictionary<IMedication>): IMedication | undefined {
  //   try {
  //     return medicationId ?
  //       Object.keys(entities)
  //         .reduce((res, key) =>
  //           entities[key].medicationId === medicationId ? entities[key] : res,
  //           {} as IMedication
  //         ) :
  //       entities[id];
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getMedication', error.message);
  //   }
  // }

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private snackBar: MatSnackBar,
    private interventionSrv: IntervencoesMedicamentosService, // TODO: ContaService changed to the API
    private medicationSrv: InterventionMedicationCustomService, // TODO: ContaService changed to the API
    private medicationCheckinSrv: MedicationCheckinCustomService,
    private personSrv: PersonCustomService,
    private msg: MsgPtBR,
    private cadastroSrv: CadastroService,
  ) { }

  /* Carrega os medicamentos considerando o dia de HOJE (referenceDate).
  * É utilizado no CARD MEDICATION do PWA.
  */
  loadMedicationsBySelectedPatient$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadMedicationsBySelectedPatient),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        // const dtEnd = this.glb.addDays(new Date(), 1); // Necessário para incluir a data do dia
        // const dtStart = this.glb.addDays(dtEnd, -state?.medication?.numOfDaysToCheckOcurrency);
        const dtEnd = null;
        const dtStart = null;
        const td = new Date();
        const referenceDate = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 23, 59, 59);
        const { cadIntervecoesMedicamentosNo, cadCheckMedicamentosNo, cadIntervecoesHorariosNo } = state?.tenant?.selectedTenant;
        return this.interventionSrv.getMedicationsForPatient(
          cadIntervecoesMedicamentosNo,
          cadCheckMedicamentosNo,
          cadIntervecoesHorariosNo,
          state?.patient?.entities[(state?.patient?.selectedId || 0)],
          dtStart,
          dtEnd,
          referenceDate)
          .pipe(
            switchMap((medications: IMedication[]) => [
              medications ? loadMedicationsSuccess({ medications }) : medicationFail({ error: null }),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              return of(medicationFail({ error: null }));
            })
          );
      })
    )
  );

  loadMedicationsByPrescription$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadMedicationsByPrescription),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        // const dtEnd = this.glb.addDays(new Date(), 1); // Necessário para incluir a data do dia
        // const dtStart = this.glb.addDays(dtEnd, -state?.medication?.numOfDaysToCheckOcurrency);
        const dtEnd = null;
        const dtStart = null;
        const td = new Date();
        const referenceDate = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 23, 59, 59);
        const { cadIntervecoesMedicamentosNo, cadIntervecoesHorariosNo } = state?.tenant?.selectedTenant;
        return this.interventionSrv.getMedicationsByPrescription(
          cadIntervecoesMedicamentosNo,
          cadIntervecoesHorariosNo,
          state?.medication?.prescriptionId,
          this.personSrv.mapToIPatient(state?.person?.person),
          dtStart,
          dtEnd)
          .pipe(
            switchMap((medications: IMedication[]) => {
              medications?.forEach((e, idx, arr) => {
                e.guid = e.medicationId > 0 ? e.medicationId?.toString() : this.glb.getGUID();
                e.order = (e?.order === null) || (e?.order === undefined) ? idx : e.order;
                // e.horarios = e.lstHorarios?.map(m => String(m.hora))?.sort((a, b) => a.localeCompare(b))?.join(",");
                e.controlado = this.glb.getReverseBoolean(e.isRestricted || false);
                e.isEditing = false;
              });
              return [
                medications ? loadMedicationsSuccess({ medications }) : medicationFail({ error: null }),
                setIsLoading({ isLoading: false })
              ];
            }),
            catchError((e: any) => {
              console.log(e);
              return of(medicationFail({ error: null }));
            })
          );
      })
    )
  );

  loadMedications$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadMedications),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.medicationSrv.getAll(
          state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo)
          .pipe(
            map(intervMed => this.interventionSrv.mapIntervToMedication(intervMed, intervMed?.horaprimeiraDose, null)),
            toArray(),
            this.checkMedicationThatIsPending$(new Date()),
            switchMap((medications: IMedication[]) => [
              medications ? loadMedicationsSuccess({ medications }) : medicationFail({ error: null }),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              return of(medicationFail({ error: null }));
            })
          );
      })
    )
  );

  deleteMedication$ = createEffect(() => this.actions$
    .pipe(
      ofType(deleteMedication),
      withLatestFrom(this.store),
      mergeMap(([action, state]) => {
        const patientId = state?.person?.person?.ocorrenciaNo;
        const { medicationId, ocorrenciaNo, dosage, medicationName } = action?.medication;
        const medicationTitle = `${medicationId} - ${medicationName}, ${dosage}`;
        return this.medicationSrv
          .deleteByOno(
            state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo,
            +ocorrenciaNo)
          .pipe(
            switchMap((wasDeleted: boolean) => [
              ...wasDeleted ?
                [
                  addLog({ log: { msg: `Medicamento excluído com sucesso: ${medicationTitle}`, showMessage: true } as ILog }),
                  deletePrescriptionMedication({ ono: ocorrenciaNo }),
                  // deleteHorariosBy({ patientId, medicationId }) FIXME: continuação do processo de eliminação do cadastro de horários
                ] :
                [addLog({ log: { msg: `Falha na tentativa de exclusão do medicamento: ${medicationTitle}`, showMessage: true } as ILog })],

              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              return of(medicationFail({ error: null }));
            })
          );
      })
    )
  );

  updateMedication$ = createEffect(() => this.actions$
    .pipe(
      ofType(updateMedication),
      mergeMap((action: any) => {
        return of(action)
          .pipe(
            withLatestFrom(this.store),
            mergeMap(([never, state]) => {
              const medication = state.medication.entities[action?.medication?.id || -1];
              // const curr_medication = this.getMedication(action?.medication?.changes.medicationId, action?.medication?.id, state.medication.entities);
              // if (!curr_medication) {
              //   return EMPTY;
              // }
              // const medication = {
              //   ...curr_medication,
              //   ...action?.medication?.changes
              // }
              const intervention: IInterventionMedication = this.interventionSrv.mapMedicationToInverv(medication, state?.login?.userName, state?.person?.person?.codigo, state?.person?.person?.nomeResidente);
              if (action.saveToDb) {
                return this.medicationSrv.save<IInterventionMedication>(
                  state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo,
                  intervention,
                  state?.tenant?.login?.usuarioLogadoNo,
                  (intervention.ocorrenciaNo || 0) > 0 ? intervention.ocorrenciaNo : undefined
                );
              }
              return of(intervention);
            }),
            switchMap((intervention: IInterventionMedication) => {
              const guid = isNaN(intervention.guid as any) ? intervention.guid : undefined;
              return [
                ...!!intervention ?
                  [
                    saveMedicationSuccess({ id: +intervention.ocorrenciaNo, guid, cor: intervention.cor }),
                    ...action.saveToDb ? [
                      addLog({ log: { id: intervention.Id_Prescricao, msg: `Medicamento atualizado com sucesso: ${intervention.Id_Medicamento} (ono: ${intervention.ocorrenciaNo})`, showMessage: true } as ILog }),
                      // TODO: TENTATIVA DE REMOVER A ATUALIZAÇÃO DOS HORÁRIOS
                      // ...intervention.lsthorariosGrid?.length > 0 ? [checkHorarios({ medication: intervention })] : [],
                    ] : [],

                  ] :

                  [
                    medicationFail({ error: `Erro ao atualizar o medicamento ${action?.medication?.changes?.medicationName}` }),
                    addLog({ log: { id: intervention.Id_Prescricao, msg: `Erro ao atualizar o medicamento: ${intervention.Id_Medicamento} (ono: ${intervention.ocorrenciaNo})`, showMessage: true } as ILog }),
                  ]

              ];
            }),
            catchError((e: any) => {
              console.log(e);
              return of(medicationFail({ error: null }));
            }),
          );
      })
    )
  );

  /* Carrega a lista de medicamentos da ANVISA. */
  loadRawMedications$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadRawMedications),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.medicationSrv.getRawAll()
          .pipe(
            switchMap((rawMedications: IMedication[]) => [
              rawMedications ? loadRawMedicationsSuccess({ rawMedications }) : rawMedicationFail(null),
            ]),
            catchError((e: any) => {
              console.log(e);
              return of(medicationFail({ error: null }));
            })
          );
      })
    )
  );

  /* Carrega o histórico de prescrições, do ambiente, anonimizado, não repetido. */
  loadHistoryMedications$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadHistoryMedications),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.medicationSrv.getHistoryAll()
          .pipe(
            switchMap((historyMedications: IMedication[]) => [
              historyMedications ? loadHistoryMedicationsSuccess({ historyMedications }) : historyMedicationFail(null),
            ]),
            catchError((e: any) => {
              console.log(e);
              return of(medicationFail({ error: null }));
            })
          );
      })
    )
  );

  /* Insere os medicamentos na INTERVENÇÃO MEDICAMENTOS.
  * Após inserção, cadastra os horários personalizados, para cada um dos medicamentos, caso haja (checkHorarios).
  * É chamado por: checkMedicationStock (quando o item não é encontrado ou não no estoque) > addMedicationStock
  */
  addInterventionMedication$ = createEffect(() => this.actions$
    .pipe(
      ofType(addInterventionMedication),
      mergeMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            if (!action?.medication?.wasChanged) {
              return EMPTY;
            }

            // tslint:disable-next-line: variable-name
            const Id_Prescricao = state?.prescription?.selectedPrescription?.ocorrenciaNo;
            const codPacienteNomedoPacienteCodMedicamento = `${state?.person?.person?.prontuario}_${state?.person?.person?.nomeResidente}_${action?.medication?.Id_Medicamento}`;
            const titulo = `Paciente: ${state?.person?.person?.nomeResidente}__${action?.medication?.medicamento}__${action?.medication?.dosagem}__${action?.medication?.Id_Medicamento || '[NOVO]'}`;
            const profissional = state?.login?.userName;
            const ordem = action?.medication?.ordem;
            const codigoPaciente = state?.person?.person?.codigo;
            const intervention: IInterventionMedication = { ...action?.medication, Id_Prescricao, codPacienteNomedoPacienteCodMedicamento, titulo, profissional, ordem, codigoPaciente };
            return this.medicationSrv.save<IInterventionMedication>(
              state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo,
              intervention,
              state?.tenant?.login?.usuarioLogadoNo,
              intervention.ocorrenciaNo || null
            ).pipe(
              map((medication: IInterventionMedication) => ({ medication, ono: intervention.ocorrenciaNo || null }))
            );
          }),
          switchMap(({ medication, ono }) => {
            if (medication) {
              const updateType = ono ? 'atualizado' : 'criado';
              const changed = { ...this.getUpdateMedication(medication), wasChanged: false };
              return [
                // TODO: TENTATIVA DE REMOVER A ATUALIZAÇÃO DOS HORÁRIOS
                // ...medication.lsthorariosGrid?.length > 0 ?
                //   [checkHorarios({ medication })] :
                //   [],

                updateMedication({ medication: changed, saveToDb: false }),
                addLog({ log: { id: medication.Id_Prescricao, msg: `Medicamento ${updateType} na Intervenção Medicamentos: ${medication.Id_Medicamento} (ono: ${medication.ocorrenciaNo}) - ${medication.medicamento}, ${medication.dosagem}`, showMessage: true } as ILog }),
                updatePrescription({ medication })
              ];
            } else {
              return [
                addLog({ log: { id: medication.Id_Prescricao, msg: `Erro na adição em Intervenção Medicamentos: ${medication.Id_Medicamento} - ${medication.medicamento}, ${medication.dosagem}`, showMessage: true } as ILog }),
                medicationFail({ error: 'error-addMedicaton-Stock' }),
              ];
            }
          }),
          catchError((e: any) => {
            console.log(e);
            return of(medicationFail({ error: 'error-addMedicaton-Stock' }));
          })
        )
      }),
    )
  );

  /* Pega uma lista de todos os medicamentos do paciente que estão na INTERVENÇÃO MEDICAMENTO.
  * Se encontrar o medicamento cadastrado: (updatePrescription) atualiza o xml da medicação sobrescrevendo o IdMedicamento de cada medicamento, no xml - PRESCRIÇÃO
  * Se não encontrar o medicamento: (addMedicationsandInsertStock) insere os medicamentos na INTERVENÇÃO MEDICAMENTO, depois atualiza o xml na PRESCRIÇÃO
  */
  checkMedicationIntervertion$ = createEffect(() => this.actions$
    .pipe(
      ofType(checkMedicationIntervention),
      mergeMap((action: any) => {
        return of(action)
          .pipe(
            withLatestFrom(this.store),
            mergeMap(([never, state]) => {
              if (!action?.medication?.wasChanged) {
                return EMPTY;
              }

              // tslint:disable: variable-name
              const Id_Prescricao = state?.prescription?.selectedPrescription?.ocorrenciaNo;
              return this.medicationSrv.getByPrescription(state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo, Id_Prescricao)
                .pipe(
                  toArray(),
                  switchMap((medications: IInterventionMedication[]) => {
                    try {
                      const medication_dosagem = `${action?.medication?.medicamento}_${action?.medication?.dosagem}`;
                      const findedMedication = this.getMedicationByTitle(medications, medication_dosagem);
                      let medication: IInterventionMedication;
                      if (findedMedication) {
                        const { codigo, codigoMedicamentoPaciente, codPacienteNomedoPacienteCodMedicamento } = findedMedication;
                        medication = { ...action?.medication, codigo, codigoMedicamentoPaciente, codPacienteNomedoPacienteCodMedicamento, Id_Prescricao };
                      } else {
                        medication = { ...action?.medication };
                      }
                      if (this.getInterventionByPrescriptionId(medications, Id_Prescricao)) {
                        const changed = { ...this.getUpdateMedication(medication), wasChanged: false };
                        return [
                          addLog({ log: { id: Id_Prescricao, msg: `Medicamento localizado na Intervenção Medicamentos: ${medication.Id_Medicamento} - ${medication.medicamento}, ${medication.dosagem}`, showMessage: true } as ILog }),
                          updateMedication({ medication: changed, saveToDb: true }),
                          updatePrescription({ medication })
                        ];
                      } else {
                        return [
                          addLog({ log: { id: Id_Prescricao, msg: `Medicamento NÃO localizado na Intervenção Medicamentos: ${medication.Id_Medicamento} - ${medication.medicamento}, ${medication.dosagem}`, showMessage: true } as ILog }),
                          addInterventionMedication({ medication })
                        ];
                      }
                    } catch (error) {
                      this.log.Registrar(this.constructor.name, 'checkMedicationIntervention', error.message);
                    }
                    return [medicationFail({ error: 'error-addMedicaton-Stock' })];
                  })
                )
            })
          )
      }),
      catchError((e: any) => {
        console.log(e);
        return of(medicationFail({ error: null }));
      })
    )
  );

  confirmMedication$ = createEffect(() => this.actions$
    .pipe(
      ofType(confirmMedication),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        const { medication, patient, observation, updateStock } = state?.medication?.confirmItem;
        if (medication) {
          const updatedMedication = { ...medication };
          updatedMedication.took = medication?.took;
          const checkin = this.medicationCheckinSrv.mapToIMedicationCheckin(updatedMedication, patient, observation || '', state?.tenant?.loggedUserName);
          updatedMedication.status = checkin.status;
          // Pipe principal
          return this.medicationCheckinSrv
            .save(state?.tenant?.selectedTenant?.cadCheckMedicamentosNo, checkin, patient, updatedMedication, (state?.loginPWA?.selectedLogin as ILogin)?.usuarioLogadoNo, -1, true)
            .pipe(
              map((checkedCount: number) => checkedCount > 0),
              this.afterSaveMedicationShowSnackBar(state?.medication?.confirmItem),
              tap(() => this.interventionSrv.clearCache()),
              switchMap((wasChecked: boolean) => {
                updatedMedication.took = wasChecked;
                updatedMedication.observation = observation || '';
                updatedMedication.isSaving = false;
                return [
                  upsertMedication({ medication: updatedMedication }),
                  stockChange({
                    updateStock,
                    medicationId: medication?.medicationId,
                    dailyQuantity: medication?.presentation === 'GOTAS' ? -medication?.dailyQuantityGotas : -medication?.dailyQuantity,
                    message: this.msg.STOCK_CHANGE_BY_APP
                  }),
                  setIsLoading({ isLoading: false })
                ];
              })
            );
        } else {
          return of(medicationFail({ error: null }));
        }
      }),
      catchError((e: any) => {
        console.log(e);
        return of(medicationFail({ error: null }));
      })
    )
  );

  confirmAllMedications$ = createEffect(() => this.actions$
    .pipe(
      ofType(confirmAllMedications),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        const entities = state?.medication?.entities;
        if (entities) {
          const patient = state?.patient?.entities[state?.patient?.selectedId];
          const observation = '';
          const { medications, checkins } = this.extractChangedMedicationsAndCheckins(entities, patient, observation, state?.tenant?.loggedUserName);
          // Pipe principal
          return this.medicationCheckinSrv
            .saveAll(state?.tenant?.selectedTenant?.cadCheckMedicamentosNo, checkins)
            .pipe(
              map((checkedCount: number) => checkedCount > 0),
              this.afterSaveMedicationShowSnackBar({ medication: { took: true } } as unknown as any),
              tap(() => this.interventionSrv.clearCache()),
              switchMap((wasSaved: boolean) => {
                if (!wasSaved) {
                  return [setIsLoading({ isLoading: false })];
                }

                const updatedMedications = []
                const updatedStock = []

                medications.map(medication => {
                  const status = checkins.find(f => +f.Id_Medicamento === +medication.medicationId)?.status || '';
                  updatedMedications.push({
                    ...medication,
                    took: true,
                    observation: observation || '',
                    isSaving: false,
                    wasChanged: false,
                    status
                  })

                  updatedStock.push({
                    updateStock: state?.medication?.confirmItems?.updateStock,
                    medicationId: medication?.medicationId,
                    dailyQuantity: medication?.presentation === 'GOTAS' ? -medication?.dailyQuantityGotas : -medication?.dailyQuantity,
                    message: this.msg.STOCK_CHANGE_BY_APP
                  })
                })

                return [
                  upsertMedications({ medications: updatedMedications }),

                  // FIXME: ATENÇÃO: Implementado todo o processo de baixa no estoque para múltiplos itens. App e API.
                  stockChanges({ stock: updatedStock }),
                  setIsLoading({ isLoading: false })
                ];
              })
            );
        } else {
          return of(medicationFail({ error: null }));
        }
      }),
      catchError((e: any) => {
        console.log(e);
        return of(medicationFail({ error: null }));
      })
    )
  );

  /* Operador personalizado para realizar operações após a medicação ter sido salva,
  * ou sinalizar que houve problemas.
  */
  protected afterSaveMedicationShowSnackBar = (item: { medication: IMedication, patient: IPatient, observation: string }) => map((wasSaved: boolean) => {
    let msg = '';
    if (wasSaved) {
      msg = item?.medication.took ? this.msg.MEDICATION_SUCCESS : `${this.msg.MEDICATION_REJECTED}: ${item?.observation}`;
    } else {
      msg = this.msg.MEDICATION_CHECKIN_FAIL;
    }
    this.snackBar.open(msg, null, {
      duration: 2000,
      panelClass: 'snackbar'
    });
    return wasSaved;
  });

  /* Irá marcar as medicações que estiverem pendentes, no cache.
  * // FIXME: Atenção: a variável 30264 está fixa no código.
  */
  protected checkMedicationThatIsPending$ = (currentDate: Date) => mergeMap((medications: IMedication[]) => {
    return this.cadastroSrv
      .getItemsFromQueue()
      .pipe(
        map(items => {
          if (items && medications?.length > 0) {
            medications.forEach(medication => {
              try {
                const find = items.filter(f => {
                  return (f.cadastro as ICadastro).LstItensCadastro.find(d => {
                    return this.glb.isEqual(d.VariavelNo, 30264) && this.isTheMedication(d.ValorDado, medication, currentDate);
                  });
                });
                medication.took = medication.took || (find.length > 0);
              } catch (error) {
                this.log.Registrar(this.constructor.name, 'checkMedicationThatIsPending.forEach', error.message);
              }
            });
          }
          return medications;
        })
      );
  });

  private getUpdateMedication(medication: IInterventionMedication) {
    return {
      id: medication.guid,
      changes: this.interventionSrv.mapIntervToMedication(medication)
    } as Update<IMedication>;
  }

  /* Verifica se a chave corresponde ao medicamento desejado.
  * A chave está no padrão:  `${patient.codigo}_${medication.medicationId}_${dateString}_${hora}`,
  */
  protected isTheMedication(key: string, medication: IMedication, currentDate: Date): boolean {
    try {
      const ar = key.split('_');
      if (ar && ar.length === 4) {
        const strDate = moment(currentDate).format('DD/MM/YYYY');
        return this.glb.isEqual(medication.medicationId, ar[1]) && (strDate === ar[2]) && (medication.prescribedTime === ar[3]);
      }
      return false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isTheMedication', error.message);
    }
  }

  /* Irá verificar se o item está sendo salvo e o removerá da lista. */
  // protected removeIfIsSaving(medication: IMedication): void {
  //   try {
  //     const indexOf = this.savingItems.findIndex(f => +f.medicationId === +medication.medicationId);
  //     if (indexOf >= 0) {
  //       this.savingItems.splice(indexOf, 1);
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'removeIfIsSaving', error.message);
  //   }
  // }

  protected firstUpperCase(str: string): string {
    try {
      const siglas = ['SIGLA'];
      if (siglas.includes(str.toUpperCase())) {
        return str.toUpperCase();
      }
      return this.glb.primeiraMaiuscula(str);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'firstUpperCase', error.message);
    }
    return str;
  }

  getMedicationByTitle(medications: IInterventionMedication[], medicationDosagem: string): IInterventionMedication {
    try {
      return medications?.find(f => this.glb.isEqual(`${f.medicamento}_${f.dosagem}`, medicationDosagem));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedicationByTitle', error.message);
    }
    return null;
  }

  getInterventionByPrescriptionId(medications: IInterventionMedication[], prescriptionId: number): IInterventionMedication {
    try {
      return medications?.find(f => this.glb.isEqual(f.Id_Prescricao, prescriptionId));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getInterventionByPrescriptionId', error.message);
    }
    return null;
  }

  private extractChangedMedicationsAndCheckins(entities: Dictionary<IMedication>, patient: IPatient, observation: string, userName: string): { medications: any; checkins: any; } {
    try {
      let medications = [];
      let checkins = [];
      Object.keys(entities)?.forEach(key => {
        const medication = entities[key];
        if (medication.wasChanged) {
          medications = [...medications, medication];
          checkins = [
            ...checkins,
            this.medicationCheckinSrv.mapToIMedicationCheckin(medication, patient, observation || '', userName)
          ];
        }
      });
      return { medications, checkins };
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'extractChangedMedicationsAndCheckins', error.message);
    }
    return null;
  }


}
