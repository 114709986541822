<mat-toolbar>
  <span class="example-spacer"></span>
  <button mat-button type="button" (click)="onCloseClick($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<h2>
  {{ title }}
</h2>
  <div *ngIf="options.length > 0" class="divCheckboxs">
    <h1>Desmarque o item que ainda NÃO foi checkado: </h1>
    <div *ngFor="let medication of options; let i = index" class="divCheckbox">
      <input class="checkboxMedication" type="checkbox" name={{medication}} id={{medication}}{{i}} value={{medication}} checked="true">
      <label class="labelCheckbox" for={{medication}}{{i}}>{{medication}}</label>
    </div>
    
  </div>
<mat-toolbar>

  <button mat-button type="button" (click)="onCancelClick($event)">
    <mat-icon>cancel</mat-icon>
    <span>Cancelar</span>
  </button>

  <button mat-button type="button" (click)="onConfirmClick($event)">
    <mat-icon>check</mat-icon>
    <span>Confirmar</span>
  </button>

</mat-toolbar>