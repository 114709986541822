import { fadeAnimation } from '@medlogic/shared/utils';
import {
  clearEvolutions,
  loadEvolutionsBySelectedPatient, selectIsLoading, selectSearch,
  setEvolution,
  setSearch,
} from '@medlogic/medlogic/medlogic-state';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';

import {
  ISmartList, GlobalService, EnTheme, UnsubscribeOnDestroyAdapter,
  IListItem,
  IMedlogicEvolution,
  AppLogService,
} from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { filter, firstValueFrom, lastValueFrom, Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { selectEvolutionsAsSmartList } from '@medlogic/medlogic/medlogic-state';

@Component({
  selector: 'ml-evolution-list',
  templateUrl: './evolution-list.component.html',
  styleUrls: ['./evolution-list.component.css'],
  animations: [fadeAnimation]
})
export class EvolutionListComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  NUMBER_OF_DAYS = 30;
  groups$: Observable<ISmartList[] | any> = null;

  enTheme = EnTheme.default;
  readonly PAGE_TITLE = 'Evoluções';
  readonly NUM_MIN_OF_CHAR_SEARCH = 3;
  imgUrl = '/assets/img/Evolutions.png';
  isLoading = true;
  search$: Observable<string>;
  search = new FormControl(null);
  readOnly = false;
  isLoading$ = this.store.select(selectIsLoading);
  visible = false;
  oldGroups = [];
  loadingString: String = 'Carregando...';
  loadingSpinner: boolean = true;

  previousEventId: number;


  constructor(
    private store: Store<IAppMedlogicState>,
    private log: AppLogService,
    private glb: GlobalService,
    private nav: NavigationPwaService,
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.subs.sink = this.store.select(state => selectSearch(state))
        .subscribe(value => this.search.setValue(value));

      this.search.valueChanges.subscribe(search => this.store.dispatch(setSearch({ search })));

      const td = new Date();
      const dtStart = this.glb.addDays(td, -this.NUMBER_OF_DAYS);
      const dtEnd = this.glb.addDays(td, +1);

      this.store.dispatch(loadEvolutionsBySelectedPatient({ dtStart, dtEnd }));

      this.groups$ = this.store.select(selectEvolutionsAsSmartList).pipe(
        tap((group: any) => {
          this.oldGroups = group;
        }),
        filter(newGroups => {
          const newIdArrayString = JSON.stringify(newGroups[0]?.items.map(g => g.id));
          const oldIdArrayString = JSON.stringify(this.oldGroups[0]?.items.map(g => g.id));

          console.log(newIdArrayString, oldIdArrayString);

          if (newIdArrayString !== oldIdArrayString) {
            this.loadingString = 'Carregando...';
            return true;
          } else if (newGroups.length === 0) {
            this.loadingString = 'Não há evoluções para o período selecionado.';
            return false;
          }

          return true;
        })
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }


  onEsc($event: any): void {
    try {
      this.search.setValue('');
      this.store.dispatch(setSearch({ search: '' }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onEsc', error.message);
    }
  }

  /* Clique numa pessoa específica. */
  itemClick(
    item: IListItem<IMedlogicEvolution>,
    enTheme: EnTheme,
    readOnly: boolean
  ): void {
    try {
      if (item) {
        this.store.dispatch(setEvolution({ selectedId: item?.id }));
        this.nav.gotoEvolution(item?.id);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'itemClick', error.message);
    }
  }

  /* Botão adicionar. */
  addClick($event: any): void {
    try {
      this.store.dispatch(setEvolution({ selectedId: null }));
      this.nav.gotoEvolution(-1);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addClick', error.message);
    }
  }

  /* Click no botão voltar dessa tela. */
  onBack($event: any): void {
    try {
      this.store.dispatch(clearEvolutions());
      this.nav.gotoCardPatient();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }


}
