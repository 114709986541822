import { addLog } from '@medlogic/shared/state-log';
import { updateMedication } from './../../state-medication/+state/medication.actions';
import { executeQueue } from '@medlogic/shared/state-queue';
import { EstoqueMateriaisCustomServices, StockItemCustomService, IntervencoesMedicamentosService, NovoEstoqueDeMateriaisCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { IAppMedlogicState, IStockItem } from '@medlogic/medlogic/medlogic-shared-interfaces';
import {
  EnPosology,
  EnStockStatus,
  GlobalService, IEstoqueMateriais, IEstoqueMaterial, IInterventionMedication, ILog, ILogin, IMedicationList, MsgPtBR
} from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { defaultIfEmpty, withLatestFrom, toArray, map, filter, tap } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';
import {
  stockFail,
  stockChange, stockChanges, stockChangeSuccess, addStockItem
} from './stock.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addInterventionMedication, checkMedicationIntervention } from '../../state-medication/+state/medication.actions';
import { checkMedicationStock, addMedicationStock, checkStockItem } from './stock.actions';
import { getMedicationId } from '../../state-medication/+state/medication.reducer';
import { TypedAction } from '@ngrx/store/src/models';

import * as moment from 'moment';

// tslint:disable: variable-name
@Injectable({
  providedIn: 'root'
})
export class StockEffects {

  private showMessage = () => tap((success: boolean) => {
    const msg = success ? this.msg.STOCK_UPDATE_SUCCESS : this.msg.STOCK_UPDATE_FAIL;
    this.snackBar.open(msg, null, {
      duration: 2000,
      panelClass: 'snackbar'
    });
  });

  // private updateStock = (quantity: number, description: string, stock: IEstoqueMaterial) => {
  //   return stock?.codigo ?
  //     this.stockSrv.updateStockQuantity(stock, description) :
  //     of(false);

  // };

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private snackBar: MatSnackBar,
    private stockSrv: EstoqueMateriaisCustomServices, // TODO: ContaService changed to the API
    private stockItemSrv: StockItemCustomService, // MedicationCustomService,
    private intervMedSrv: IntervencoesMedicamentosService,
    private msg: MsgPtBR,
    private newStock: NovoEstoqueDeMateriaisCustomService,
  ) { }

  /**
   * Operador personalizado para realizar a baixa no estoque caso esteja configurado no idoso bem cuidado do cliente.
   * updateStock: funciona como uma trava, se for false retornará false diretamente sem modificar o estoque.
   */
  stockChange$ = createEffect(() => this.actions$
    .pipe(
      ofType(stockChange),
      withLatestFrom(this.store),
      tap(([never, state]) => console.log("state: ", state)),
      mergeMap(([never, state]) => {
        const { updateStock, medicationId, dailyQuantity, message } = state?.stock?.changeItem;
        const { dosage, medicationName, codPacienteNomedoPacienteCodMedicamento } = state?.medication?.confirmItem?.medication;
        const { codigo, nomeHospede } = state?.medication?.confirmItem?.patient;

        return updateStock ?
          // this.newStock.getAll(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo)
          this.newStock.getAll(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo)
            // this.newStock.getByMedicationIdPatientIdDosagem(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo, codPacienteNomedoPacienteCodMedicamento, dosage)
            // this.stockSrv.getAll(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo)
            // this.stockSrv.getByMedicationIdPatientIdDosagem(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo, medicationId, codigo, dosage)
            // this.stockSrv.getByMedicationId(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo, medicationId)
            .pipe(
              // filter(linhaEstoque => {
              //   console.log(linhaEstoque.titulo, `${medicationName} - ${dosage}_${nomeHospede}`);
              //   return linhaEstoque.titulo === `${medicationName} - ${dosage}_${nomeHospede}`;
              // }),
              // filter(linhaEstoque => {
              //   console.log(linhaEstoque.codPacienteNomedoPacienteCodMedicamento, codPacienteNomedoPacienteCodMedicamento);
              //   return linhaEstoque.codPacienteNomedoPacienteCodMedicamento === codPacienteNomedoPacienteCodMedicamento;
              // }),
              filter(linhaEstoque => {
                const medlogicId = linhaEstoque.codPacienteNomedoPacienteCodMedicamento.split("_")[0];
                // console.log(codigo, medlogicId, dosage, linhaEstoque.dosagem, medicationName, linhaEstoque.itens);
                return codigo == medlogicId && dosage == linhaEstoque.dosagem && medicationName == linhaEstoque.itens;
              }),
              tap(stock => {
                if (stock) {
                  stock.estoque = stock.estoque + dailyQuantity;
                  stock.consumoDiario = stock.consumoDiario || stock.consumoDiario2;

                  this.newStock.save(stock, (state?.loginPWA?.selectedLogin as ILogin)?.usuarioLogadoNo, stock.codigo, true)
                } else {
                  return of(stockFail({ error: null }));
                }
              }),
              // console.log("sucesso getByMedicationId", c)),
            ) :
          of(false);
      }),
      // this.showMessage(),
      defaultIfEmpty(false),
      switchMap((wasChanged: boolean) => [
        executeQueue(), // Modificada a lógica para enfileirar e disparar a execução
        wasChanged ? stockChangeSuccess() : stockFail(null),
        setIsLoading({ isLoading: false })
      ]),
      catchError((e: any) => {
        console.log(e);
        return of(stockFail(null));
      })
    )
  );

  /**
   * Operador personalizado para realizar a baixa no estoque de vários medicamentos caso esteja configurado no idoso bem cuidado do cliente.
   * updateStocks: funciona como uma trava, se for false retornará false diretamente sem modificar o estoque.
   */
  stockChanges$ = createEffect(() => this.actions$
    .pipe(
      ofType(stockChanges),
      withLatestFrom(this.store),
      tap(([never, state]) => console.log("state: ", state)),
      mergeMap(([never, state]) => {
        return from(state?.stock?.changeItems).pipe(
          mergeMap(({ updateStock, dailyQuantity, medicationId }) => {
            const { dosage, medicationName } = state?.medication?.confirmItems?.medications.find(f => f.medicationId == medicationId);
            const { codigo } = state?.medication?.confirmItems?.patient;

            if (updateStock) {
              return this.newStock.getAll(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo)
                .pipe(
                  filter(linhaEstoque => {
                    const medlogicId = linhaEstoque.codPacienteNomedoPacienteCodMedicamento.split("_")[0];
                    return codigo == medlogicId && dosage == linhaEstoque.dosagem && medicationName == linhaEstoque.itens;
                  }),
                  map(stock => {
                    if (stock) {
                      stock.estoque = stock.estoque + dailyQuantity;
                      stock.consumoDiario = stock.consumoDiario || stock.consumoDiario2;

                      return stock;
                    } else {
                      return of(stockFail(null));
                    }
                  })
                );
            } else {
              return of(stockFail(null));
            }
          })
        ).pipe(
          toArray(),
        );
      }),
      switchMap((medicationsStock: IEstoqueMaterial[]) => {
        if (medicationsStock.length > 0) {
          return this.newStock.saveAll(medicationsStock);
        } else {
          return of(stockFail(null));
        }
      }),
      // this.showMessage(),
      defaultIfEmpty(false),
      switchMap((wasChanged: boolean) => [
        executeQueue(),
        wasChanged ? stockChangeSuccess() : stockFail(null),
        setIsLoading({ isLoading: false })
      ]),
      catchError((e: any) => {
        console.log(e);
        return of(stockFail(null));
      })
    )
  );


  /* 1) Checa se o medicamento já existe na Estoque de Materiais (stock)
   *  1.1) Se já existir na Estoque de Materiais: dispara checkMedicationIntervetion para checar se já tem ele cadastrado
   *  1.1.1) Se não existir na checkMedicationIntervation, cadastra
   *  1.1.2) Checa se tem horários personalizados, se existir, limpa todos os registros, depois cadastra todos os horários.
   *
   *  1.2) Se não existir na Estoque de Materiais: dispara (checkStockItem) para checar se já existe na ITENS DE ESTOQUE
   *  1.2.1) Se não existir, cadastra na ITENS DE ESTOQUE
   * IMPORTANTE: O id do Medicamento é gerado na ESTOQUE DE MATERIAIS (V_100306)!!
   * 1.2.2) Pega o Id do Medicamento e o utiliza para cadastrar na Intervenção Medicamentos
   * FIXME: Tratamento de excessão: caso o medicamento já seja encontrado na estoque de materiais, pode ser necessário fazer um update,
   * pois a quantidade diária pode ter sido modificada com manutenção de medicamento e dosagem (ou seja, mesmo item, mas com características de uso diferentes)
   * O Id_Medicamento é gerado no Cadastro de Estoque.
É a variável V_100306 -> Essa variável pega o número da ocorrencia para usar como id do medicamento.
Na tela de INTERVENÇÕES MEDICAMENTO V2 eu utilizo a variável V_20263 para fazer um proc que recebe o valor da variável V_100306 da tela de ESTOQUE DE MATERIAIS
  */
  checkMedicationStock$ = createEffect(() => this.actions$
    .pipe(
      ofType(checkMedicationStock),
      mergeMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            const newOrChangedMedications = action?.medications?.filter(f => f.wasChanged || !f.Id_Medicamento);
            if (newOrChangedMedications?.length <= 0) {
              return EMPTY;
            }

            // const centrocusto = action?.medications[0]?.centrocusto;
            const centrocusto = state?.prescription?.selectedPrescription?.centroCusto;
            const nomeResidente = state.person?.person?.nomeResidente;
            const codpaciente = action?.medications[0]?.Id_Paciente;
            const Id_Prescricao = action?.medications[0]?.Id_Prescricao || state?.prescription?.selectedPrescription?.ocorrenciaNo;
            const stocks$ = (this.glb.isEqual(centrocusto, nomeResidente) ?
              this.stockSrv.getByCodigoPacienteOrCentroCusto(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo, codpaciente, centrocusto) :
              this.stockSrv.getByCentroCusto(state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo, centrocusto)
            );
            return stocks$
              .pipe(
                toArray(),
                tap(c => console.log("stockss", c)),
                switchMap((stocks: any[]) => {
                  const executionActions$: any[] = [];
                  newOrChangedMedications?.forEach((medication: IInterventionMedication) => {
                    const medicationDosage = `${medication?.medicamento}_${medication?.dosagem}`;
                    const Id_Paciente = medication.Id_Paciente || state?.person?.person?.prontuario;
                    // FIXME: Atenção: pode estar havendo confusão entre o centro de custo do medicamento e o de estoque.
                    // O centro de custo do medicamento aponta para o paciente quanto do estoque de onde o item se origina.
                    const stockFinded = this.hasMedicationInStock(stocks, medicationDosage, centrocusto);
                    if (stockFinded) {
                      const Id_Medicamento = stockFinded.ocorrenciaNo?.toString();
                      const IdMedicamento_IdPaciente = `${Id_Medicamento}_${Id_Paciente}`;
                      medication = ({ ...medication, Id_Prescricao, Id_Paciente, Id_Medicamento, IdMedicamento_IdPaciente, uNIDADEDENEGOCIO: stockFinded.unidadeNegocio } as IInterventionMedication);
                      executionActions$.push(addLog({ log: { id: Id_Prescricao, msg: `Item localizado no Estoque: ${Id_Medicamento} - ${medicationDosage}, ${medication.centrocusto}`, showMessage: true } as ILog }));
                      executionActions$.push(addMedicationStock({ medication })); // Executa da mesma forma que se não encontrado no estoque para atualizar os dados do item no estoque.
                      executionActions$.push(checkMedicationIntervention({ medication }));
                      executionActions$.push(this.getUpdateMedicationState(stockFinded, medication));
                    } else {
                      executionActions$.push(addLog({ log: { id: Id_Prescricao, msg: `Item NÃO localizado no Estoque: ${medicationDosage}, ${medication.centrocusto}`, showMessage: true } as ILog }));
                      executionActions$.push(addMedicationStock({ medication: { ...medication, Id_Paciente, Id_Prescricao } }));
                      executionActions$.push(checkStockItem({ medication }));
                    }
                  });
                  return executionActions$;
                }),
                catchError((e: any) => {
                  console.log('checkMedicationStock switchMap', e);
                  return of(stockFail(null));
                })
              )
          }),
          catchError((e: any) => {
            console.log('checkMedicationStock', e);
            return of(stockFail(null));
          })
        )
      }))
  );

  addMedicationStock$ = createEffect(() => this.actions$
    .pipe(
      ofType(addMedicationStock),
      mergeMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            if (!action?.medication?.wasChanged && action?.medication?.Id_Medicamento) {
              return EMPTY;
            }
            const intervention = { ...action?.medication, };
            const stockItem = this.mapToStock(intervention);
            const onoBeforeSave = +action?.medication?.Id_Medicamento || null;
            return this.stockSrv.save<IEstoqueMateriais>(
              state?.tenant?.selectedTenant?.cadEstoqueMateriaisNo,
              stockItem,
              state?.tenant?.login?.usuarioLogadoNo,
              onoBeforeSave
            ).pipe(
              map(ocorrenciaNo => ({
                onoBeforeSave,
                stock: { ...stockItem, ocorrenciaNo } as IEstoqueMateriais,
                Id_Prescricao: action?.medication?.Id_Prescricao || state?.prescription?.selectedPrescription?.ocorrenciaNo
              }))
            );
          }),
          switchMap(({ onoBeforeSave, stock, Id_Prescricao }) => {
            const updateType = onoBeforeSave ? 'atualizado' : 'adicionado';
            const medication: IInterventionMedication = this.mapStockToInvervention(stock, action, Id_Prescricao);
            return [
              ...!!stock ?
                [
                  stockChangeSuccess(),
                  addLog({ log: { id: Id_Prescricao, msg: `Medicamento ${updateType} no controle de Estoque: ${medication.Id_Medicamento} (ono: ${medication.ocorrenciaNo || 'NOVO'}) - ${medication.medicamento}, ${medication.dosagem}`, showMessage: true } as ILog }),
                  { ...this.getUpdateMedicationState(stock, medication), wasChanged: false },
                  addInterventionMedication({ medication }) // A princípio, já tentará adicionar na intervenção tendo o id.
                ] :
                [
                  stockFail({ error: 'error-addMedicaton-Stock' }),
                  addLog({ log: { id: Id_Prescricao, msg: `Houve falha na inserção no controle de Estoque: ${medication.Id_Medicamento} (ono: ${medication.ocorrenciaNo || 'NOVO'}) - ${medication.medicamento}, ${medication.dosagem}`, showMessage: true } as ILog }),
                ],
            ];
          }),
        )
      }),
      catchError((e: any) => {
        console.log(e);
        return of(stockFail({ error: 'error-addMedicaton-Stock' }));
      })
    )
  );

  private mapStockToInvervention(stock: IEstoqueMateriais, action: { medication: IInterventionMedication; } & TypedAction<"SISTEMA [Stock/API] Add Medication Stock">, Id_Prescricao: number): IInterventionMedication {
    const Id_Medicamento = stock?.ocorrenciaNo?.toString();
    const codigoMedicamentoPaciente = `${Id_Medicamento}__${action?.medication?.Id_Paciente}`;
    // O título atual segue ao padrão do Estoque de Materiais
    const titulo = `Paciente: ${stock?.centroCusto}__${action?.medication?.medicamento}__${action?.medication?.dosagem}__${Id_Medicamento}`;
    const IdMedicamento_IdPaciente = `${Id_Medicamento}_${action?.medication?.Id_Paciente}`;
    const intervention: IInterventionMedication = {
      ...action?.medication,
      codigoMedicamentoPaciente,
      titulo,
      Id_Medicamento,
      IdMedicamento_IdPaciente,
      Id_Prescricao
    };
    return intervention;
  }

  private getUpdateMedicationState(stockFinded: IEstoqueMateriais, medication: IInterventionMedication): any {
    try {
      const updated = {
        id: getMedicationId(medication),
        changes: {
          ...this.intervMedSrv.mapIntervToMedication(medication, null, null),
          date: this.glb.dateToYYYYMMddThhmmss(new Date()),
          prescribedTime: medication?.horaprimeiraDose,
          isSos: this.glb.isEqual(medication?.posologia, 'SOS'),
          medicationId: stockFinded?.ocorrenciaNo,
          businessUnit: stockFinded?.unidadeNegocio,
          Id_Medicamento: stockFinded?.ocorrenciaNo,
          Id_Medicamento_Id_Paciente: `${stockFinded.ocorrenciaNo}_${medication.Id_Paciente}`,
          codPacienteNomedoPacienteCodMedicamento: `${medication.Id_Paciente}_${medication.centrocusto}_${stockFinded.ocorrenciaNo}`
        }
      };
      return updateMedication({ medication: updated, saveToDb: false });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUpdateMedicationState', error.message);
    }
    return null;
  }

  hasMedicationInStock(estoqueMateriais: IEstoqueMateriais[], medicationAndDosagem: string, costCenter: string = null): IEstoqueMateriais {
    try {
      return estoqueMateriais?.find(f =>
        this.glb.isEqual(`${f.itens}_${f.dosagem}`, medicationAndDosagem, true, true) &&
        (!costCenter || this.glb.isEqual(f.centroCusto, costCenter))
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasMedicationInStock', error.message);
    }
    return null;
  }

  getMedicationByName(medications: IMedicationList[], nomeMedicamento: string): IMedicationList {
    try {
      if (medications?.length > 0) {
        return medications?.find(f => this.glb.isEqual(f.titulo, nomeMedicamento));
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedicationByName', error.message);
    }
    return null;
  }

  mapToStock(intervention: IInterventionMedication) {
    try {
      const consumoDiario2 = this.numOfTimesADay(intervention) * parseInt(this.glb.removeNonDigit(intervention?.dosagem), 10);
      return {
        idPaciente: intervention?.Id_Paciente,
        dosagem: intervention?.dosagem,
        dose: parseInt(this.glb.removeNonDigit(intervention?.dosagem), 10),
        unidadeDose: this.glb.removeNumber(intervention?.dosagem),
        centroCusto: intervention?.centrocusto,
        uniNegocio: intervention?.uNIDADEDENEGOCIO,
        unidadeNegocio: intervention?.uNIDADEDENEGOCIO,
        unidademedida: intervention?.apresentacao,
        medicamentoControlado: intervention?.medicamentoControlado,
        tipoMaterial: intervention?.tIPOMaterial,
        codPacienteNomedoPacienteCodMedicamento: `${intervention?.Id_Paciente}_${intervention?.centrocusto}_${intervention?.medicamento}`,
        itens: intervention?.medicamento,
        titulo: `${intervention?.medicamento} - ${intervention?.dosagem}_${intervention?.centrocusto}`,
        idMedicamento: intervention?.Id_Medicamento,
        tipoMedicamentosCodPaciente: `${intervention?.materialId}_${intervention?.Id_Paciente}`,
        concatUniNegocioSIM: `${intervention?.medicamento}_${intervention?.centrocusto}`,
        concatUniNegocioNAO: `${intervention?.medicamento}_${intervention?.centrocusto}`,
        codCenCusto: `${intervention?.Id_Medicamento}_${intervention?.centrocusto}`,
        consumoDiario2,
        consumoDiario: intervention?.quantUtilizadaD,
        gOTASPMl: intervention?.quantUtilizadaGota || (intervention?.quantUtilizadaD / 20),
        dataAtual: new Date(),
        hoje: new Date(),
        habilitado: true,
        materialID: intervention?.materialId,
        estoqueMinimo: consumoDiario2 * 30,
        status: EnStockStatus.Comprar,
        daysToExpire: 0,
        //
        medicamento: intervention?.medicamento,
        medicamento2: intervention?.medicamento,
      } as IEstoqueMateriais;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToStock', error.message);
    }
    return null;
  }

  numOfTimesADay(intervMed: IInterventionMedication): number {
    try {
      let result = [];
      const posology = intervMed.posologia ? intervMed.posologia.toUpperCase() : '';
      if (posology !== EnPosology.customized) {
        switch (posology) {
          default:
          case EnPosology.p12in12h:
            result = this.getItemHorario(intervMed.horaprimeiraDose, 12);
            break;
          case EnPosology.p8in8h:
            result = this.getItemHorario(intervMed.horaprimeiraDose, 8);
            break;
          case EnPosology.p6in6h:
            result = this.getItemHorario(intervMed.horaprimeiraDose, 6);
            break;
          case EnPosology.p1perDay:
            result = this.getItemHorario(intervMed.horaprimeiraDose, 24);
            break;
          case EnPosology.perDay:
            result = this.getItemHorario(intervMed.horaprimeiraDose, 24);
            break;
          case EnPosology.perWeek:
            result = this.getItemHorario(intervMed.horaprimeiraDose, 24);
            break;
          case EnPosology.sos:
            result = this.getItemHorario(intervMed.horaprimeiraDose, 24);
            break;
          case EnPosology.hourInterval:
            result = this.getItemHorario(intervMed.horaprimeiraDose, intervMed.intervaloHoras);
            break;
        }
      }
      return result?.length;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calcConsumoDiario', error.message);
    }
    return 0;
  }

  /** Retorna uma lista de horários a partir de uma hora inicial e um intervalo entre os horários. */
  getItemHorario(horaInicio: string, intervalo: number): string[] {
    const result = [];
    try {
      // Existe a possibilidade do intervalo ser uma fração de um dia, mas também de ser um número de dias, exemplo a cada 72 horas
      const contHorario = intervalo <= 24 ? 24 / intervalo : intervalo;
      if (horaInicio !== '' || undefined) {
        result.push(horaInicio);
      } else {
        result.push('06:00');
      }
      for (let index = 1; index < contHorario; index++) {
        // TODO: Porquê essa data fixa? - talvez porque o resultado é baseado em horário e a data será ignorada
        const date = moment('29/11/2018' + result[index - 1], 'DD/MM/YYYY h:m').add(intervalo, 'hours');
        result.push(moment(date).format('HH:mm'));
      }
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getItemHorario', error.message);
      return [];
    }
  }

  /* *** TODO: CADASTRO DE ITENS DE ESTOQUE - DEVERIAM ESTAR EM OUTRO STATE!!! *** */
  /*  *** *** ATENÇÃO: Essa é a rotina para atualizar o cadastro: ITENS DE ESTOQUE.
  * Para simplificação, não foi criado um state específico para essa outra entidade.
  */
  addStockItem$ = createEffect(() => this.actions$
    .pipe(
      ofType(addStockItem),
      mergeMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            const tipoMaterial = action?.medication?.tIPOMaterial;
            const habilitado = true;
            const quantidadeEmbalagem = 1;
            const titulo = action?.medication?.medicamento;
            const stockItem = { titulo, tipoMaterial, habilitado, quantidadeEmbalagem } as IStockItem;
            const Id_Prescricao = action?.medication?.Id_Prescricao;
            return this.stockItemSrv.save<IStockItem>(
              state?.tenant?.selectedTenant?.cadItensEstoquenNo,
              stockItem,
              state?.tenant?.login?.usuarioLogadoNo,
              null
            ).pipe(
              switchMap((newStockItem: IStockItem) => {
                return [
                  ...!!newStockItem ?
                    [
                      stockChangeSuccess(),
                      addLog({ log: { id: Id_Prescricao, msg: `Adicionado na lista de Itens: ${newStockItem.titulo}`, showMessage: true } as ILog }),
                    ] :
                    [stockFail({ error: 'error-addStockItem' })],
                ];
              }),
            )
          }),
          catchError((e: any) => {
            console.log(e);
            return of(stockFail({ error: 'error-addStockItem' }));
          })
        )
      })
    )
  );

  /* *** *** ATENÇÃO: Checa o item no cadastro de ITENS DE ESTOQUE */
  checkStockItem$ = createEffect(() => this.actions$
    .pipe(
      ofType(checkStockItem),
      mergeMap((action: any) => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            // tslint:disable: variable-name
            const nomeMedicamento = action?.medication?.medicamento;
            const Id_Prescricao = state?.prescription?.selectedPrescription?.ocorrenciaNo;
            return this.stockItemSrv.getByNomeMedicamento(state?.tenant?.selectedTenant?.cadItensEstoquenNo, nomeMedicamento)
              .pipe(
                toArray(),
                switchMap((stockItems: IStockItem[]) => {
                  if (stockItems) {
                    const stockItem = this.getMedicationByName(stockItems, action?.medication.medicamento);
                    let medication: IInterventionMedication;
                    if (stockItem) {
                      // const codigomedicamento = stockItem?.codigo; // Código do medicamento vem da Estoque de Materiais e não da Itens de Estoque
                      const codigoMedicamentoPaciente = `${stockItem?.codigo}__${action?.medication?.codigoPaciente}`
                      const titulo = `${action?.medication?.medicamento} - ${action?.medication?.dosagem}_${action?.medication?.codigoPaciente}`;
                      const identificacao = `${action?.medication?.medicamento}_${action?.medication?.codigoPaciente}_${this.glb.dateToddMMYYYY(new Date())}`;
                      medication = { ...action?.medication, codigoMedicamentoPaciente, titulo, identificacao, Id_Prescricao }
                    } else {
                      medication = { ...action?.medication }
                    }
                    return [
                      this.hasMedicationName(stockItems, action?.medication.medicamento) ?
                        stockChangeSuccess() :
                        addStockItem({ medication }),
                    ];
                  }
                  return [stockFail({ error: 'error-checkStockItem' })]
                })
              )
          })
        )
      }),
      catchError((e: any) => {
        console.log(e);
        return of(stockFail(null));
      })
    )
  );

  private hasMedicationName(medications: IMedicationList[], nomeMedicamento: string): boolean {
    try {
      if (medications.length > 0) {
        return !!medications.find(f => this.glb.isEqual(f.titulo, nomeMedicamento));
      } else {
        return false;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasMedicationName', error.message);
    }
    return false;
  }


}
