import { PatientCarePlanActivityDetailComponent } from './pwa/view/patient-care-plan-activity-detail/patient-care-plan-activity-detail.component';
import { MedlogicUiMedlogicUiCarePlanModule } from '@medlogic/medlogic/ui/medlogic-ui-care-plan';
import { PatientCarePlanComponent } from './pwa/view/patient-care-plan/patient-care-plan.component';
import { SharedUtilsModule } from '@medlogic/shared/utils';
import { UiSmartListModule } from '@medlogic/shared/ui/ui-smart-list';
import { SharedSharedJsstoreModule } from '@medlogic/shared/shared-jsstore';
// Project modules
// Angular
import { FormsModule } from '@angular/forms';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Component
import { CardMedicationComponent } from './pwa/view/card-medication/card-medication.component';
import { PatientTimelineComponent } from './pwa/view/patient-timeline/patient-timeline.component';

// Pipe
// Directive
// Services
// import { CookieModule } from 'ngx-cookie';
import { CardPatientComponent } from './pwa/view/card-patient/card-patient.component';
import { AppAssistModule } from './app-assist.module';
import { ResponsibleFamilyService } from './pwa/service/responsible-family.service';
import { CardPatientProvider } from './pwa/provider/card-patient.provider';
import { PatientHeaderComponent } from './pwa/view/patient-header/patient-header.component';
import { PatientListComponent } from './pwa/view/patient-list/patient-list.component';
import { PatientActionComponent } from './pwa/view/patient-action/patient-action.component';
import { VitalSignComponent } from './pwa/view/vital-sign/vital-sign.component';
import { EmergencyDataComponent } from './pwa/view/emergency-data/emergency-data.component';
import { MedicationDialogComponent } from './pwa/components/medication-dialog/medication-dialog.component';
import { PatientKeywordFilterPipe } from './assist/pipe/patient-keyword-filter.pipe';
import { FilterByIncludeNormalPipe } from './pwa/pipe/filter-by-include-normal.pipe';
import { PatientPipePipe } from './pwa/pipe/patient-pipe.pipe';
import { NavigationPwaService } from './pwa/service/navigation-pwa.service';
import { DadoDALCustom } from './pwa/model/dado-dal.custom';
import { MedicationCheckinPwaCustomService } from './pwa/service/medication-checkin-pwa.custom.service';
import { MedicationPendingPipe } from './pwa/pipe/medication-pending.pipe';
import { EvolutionTimelineComponent } from './pwa/view/evolution-timeline/evolution-timeline.component';
import { PainScaleComponent } from './pwa/components/pain-scale/pain-scale.component';
import { GenericActivityComponent } from './pwa/view/generic-activity/generic-activity.component';
import { GeCoreModule } from '@medlogic/shared/gecore';
import { LocalCoreModule } from './module/core/local-core.module';
import { PrimeNgModule } from './module/core/primeng.module';
import { GeFormModule } from '@medlogic/shared/geform';
import { MaterialModule } from '@medlogic/shared/material';
import { ConfigPwaMedLogicService } from './pwa/service/config-pwa-medlogic.custom.service';
import { NavigationService } from './pwa/service/navigation.service';
import { NgLetModule } from '@ngrx-utils/store';
import { VitalSignListComponent } from './pwa/view/vital-sign-list/vital-sign-list.component';
import { EvolutionListComponent } from './pwa/view/evolution-list/evolution-list.component';
import { StockSyncDialogComponent } from './pwa/components/stock-sync-dialog/stock-sync-dialog.component';
import { SelectPatientDialogComponent } from './pwa/components/select-patient-dialog/select-patient-dialog.component';
import { CarePlanReportComponent } from './pwa/view/care-plan-report/care-plan-report.component';
import { DashboardComponent } from './pwa/view/dashboard/dashboard.component';
import { MatIconModule } from '@angular/material/icon';

const PIPES = [
  PatientPipePipe,
  PatientKeywordFilterPipe,
  FilterByIncludeNormalPipe,
  MedicationPendingPipe,
];

const COMPONENTS = [
  CardMedicationComponent,
  PatientTimelineComponent,
  CardPatientComponent,
  PatientHeaderComponent,
  PatientActionComponent,
  PatientListComponent,
  PatientCarePlanComponent,
  PatientCarePlanActivityDetailComponent,
  VitalSignComponent,
  EmergencyDataComponent,
  EvolutionTimelineComponent,
  PainScaleComponent,
  GenericActivityComponent,
  VitalSignListComponent,
  EvolutionListComponent,
  CarePlanReportComponent,
  DashboardComponent,
];

const DIALOGS = [
  MedicationDialogComponent,
  StockSyncDialogComponent,
  SelectPatientDialogComponent
];

const MODULES = [
  GeCoreModule,
  GeFormModule,
  LocalCoreModule,
  MaterialModule,
  PrimeNgModule,
  CommonModule,
  AppAssistModule,
  NgLetModule,
  FormsModule,
  RouterModule,
  SharedSharedJsstoreModule,
  UiSmartListModule,
  SharedUtilsModule,
  // Libs
  MedlogicUiMedlogicUiCarePlanModule,
  MatIconModule,
]

// Classes
@NgModule({
  declarations: [
    PIPES,
    COMPONENTS,
    DIALOGS
  ],
  exports: [
    COMPONENTS,
    DIALOGS,
    PIPES
  ],
  imports: [
    MODULES
  ],
  entryComponents: [
    DIALOGS
  ],
  providers: [
    ConfigPwaMedLogicService,
    NavigationService,
    ResponsibleFamilyService,
    CardPatientProvider,
    ResponsibleFamilyService,
    NavigationPwaService,
    MedicationCheckinPwaCustomService,
    DadoDALCustom
  ]
})
export class AppPwaModule {

  /* Para previnir de multiplas importações desse módulo, que deve ser singleton */
  constructor(@Optional() @SkipSelf() parentModule: AppPwaModule) {
    if (parentModule) {
      throw new Error(
        'AppPwaModule is already loaded. Import it in the AppModule only');
    }
  }


}
