import { ISelectPatientDialog } from './../../components/select-patient-dialog/select-patient-dialog.component';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppLogService, IPatient, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { SelectPatientDialogComponent } from '../../components/select-patient-dialog/select-patient-dialog.component';
import { swapImagePerson } from '../../service/pwa-util';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ml-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.css']
})
export class PatientHeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() patient: IPatient;
  @Input() showMiniImg: boolean;

  @Input() showChangePatient = false;
  @Input() patients: IPatient[];

  @Output() select? = new EventEmitter<any>()

  @Output() patientSelected = new EventEmitter<IPatient>();

  constructor(
    private log: AppLogService,
    private glb: GlobalService,
    private store: Store<IAppMedlogicState>,
    private matDialogSrv: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
  }

  /* Retorna a idade baseado na data de nascimento. */
  getAge(birthDate: Date): number {
    try {
      if (birthDate !== undefined) {
        return this.glb.GetYearsOld(this.glb.getTypedValue(birthDate).value);
      } else {
        return 0;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAge', error.message);
    }
  }

  swapImage(img: EventTarget, patient: IPatient): void {
    try {
      swapImagePerson(img, patient?.sexo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'swapImage', error.message);
    }
  }

  functionSelect(event){
    this.select.emit(event)
  }
  selectPatient(): void {
    try {
      this.showDialog(this.patients);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'selectPatient', error.message);
    }
  }

  showDialog(patients: IPatient[], showMiniImg: boolean = false): void {
    try {
      const width = '100%';
      const height = '100vh';
      const dialogRef = this.matDialogSrv.open(SelectPatientDialogComponent, {
        width,
        height,
        minWidth: width,
        minHeight: height,
        data: {
          patients,
          showMiniImg
        } as ISelectPatientDialog
      });
      this.subs.sink = dialogRef.afterClosed()
        .subscribe(patient => {
          try {
            if (patient) {
              this.patientSelected.emit(patient);
              // this.store.dispatch(setPatient({ selectedId: +patient.codigo }));
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'showDialog', error.message);
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'confirm', error.message);
    }
  }


}
