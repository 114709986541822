import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'ui-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.css']
})
export class DialogConfirmComponent implements OnInit {

  private _title: string;
  public options: string[]
  public get title(): string {
    return this._title;
  }
  public set title(v: string) {
    this._title = v;
  }

  constructor(
    protected log: LogService,
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.options = data.options
  }

  ngOnInit() {
  }

  /* Click em fechar. */
  onCloseClick(event?: any): void {
    try {
      this.dialogRef.close(false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onCloseClick', error.message);
    }
  }

  /* Click em confirmar. */
  onConfirmClick(event?: any): void {
    try {
      var checkedNumbers = [];
      var checkboxIds = this.options;

      for (var i = 0; i < checkboxIds.length; i++) {
          var checkbox = document.getElementById(`${checkboxIds[i]}`+i);
          if (checkbox instanceof HTMLInputElement && checkbox.type === "checkbox") {
              if (!checkbox.checked) {
                  checkedNumbers.push(i);
              }
          }
      }
      this.dialogRef.close({wasConfirmed:true, dialogSelecteds:checkedNumbers});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onConfirmClick', error.message);
    }
  }

  /* Click em cancelar. */
  onCancelClick(event?: any): void {
    try {
      this.dialogRef.close({wasConfirmed:false});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onCancelClick', error.message);
    }
  }


}
