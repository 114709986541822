import { GlobalService, ConfigJsonService, error, routePostWebService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { WsTrackerService } from './ws-tracker.service';
import { EnWsTrackStatus } from '@medlogic/shared/shared-interfaces';
import { CacheService } from './cache-service.service';
import { of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { publishReplay } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import * as xml2js from 'xml2js';
import * as processors from 'xml2js/lib/processors';
import { throwError } from 'rxjs';
import { FhirActivityDetailService } from '@medlogic/fhir';
/* @classdesc Classe que centralizará a forma de todas as chamadas ao serviço.
 * É importante, pois, poderá centralizar os mecanismos de autenticação, compactação,
 * tratamento de erros de I/OnInit, também modo offline.
 * No entanto, o nome e padrão de chamada do método se aproxima ao que era utilizado no Flash.
 * Será importante criar métodos diferentes com palavras de get, post, etc de maneira distinta.
 *  */
@Injectable()
export class WebService extends UnsubscribeOnDestroyAdapter {

  // TODO: Substituir o token pelo token da sessão do usuário. Checar se o asmx já está preparado para isso e remover o token fixo.
  // private token = '####TOKEN###';
  private BUILD_VERSION: string;
  // Será substituído na api
  private readonly TOKEN_STR = '###TOKEN###';
  private readonly USERID_STR = '###USER_ID###';
  // To avoid a excessive number of connections with same parameters in a period of time
  private MAX_NUM_OF_SAME_CONNECTIONS = 20;
  private TIME_INTERVAL_SEC = 30;
  private CONNECTIONS_COUNT: { [key: string]: { count: number, dtStart: Date } } = {};

  public get baseUrl(): string {
    // return this.cnfJson.wsdlUrl.replace('?wsdl', '');
    return this.cnfJson.baseUrlAPI + routePostWebService;
  }

  constructor(
    private http: HttpClient,
    private cnfJson: ConfigJsonService,
    private log: LogService,
    private cacheService: CacheService,
    private wsTracker: WsTrackerService,
    private global: GlobalService,
    private activityDetailSrv: FhirActivityDetailService
  ) {
    super();
  }

  /* @method connect
   * Envia um comando Get a camada de serviço e obtem o retorno.
   */
  // tslint:disable-next-line: ban-types
  get(url: string, onError?: Function): Observable<any> {
    try {
      return this.http.get(url).pipe(catchError((err: any) => throwError(err || 'Server error')));
    } catch (error) {
      this.log.Registrar('Webservice', 'get', error.message);
    }
  }

  /* Utiliza o componente https://www.npmjs.com/package/ng2-cache para cache dos dados. */
  // tslint:disable-next-line: ban-types
  connectWithCache<T>(method: string, params: any[], firstTagName?: string): Observable<T> {
    try {
      const key = this.wsTracker.getBaseKey(method, params);
      // get some data by key, null - if there is no data or data has expired
      return this.cacheService.get(key)
        .pipe(
          mergeMap((cache) => {
            try {
              if (cache) {
                return of(cache);
              }
              return this.connect<T>(method, params, firstTagName);
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'connectWithCache.mergeMap', error.message);
            }
            return EMPTY;
          }),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'connectWithCache', error.message);
    }
    return EMPTY;
  }

  /* Limpa o cache de todas as tags */
  cleanAllCache(): void {
    try {
      this.cacheService.removeAll();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanAllCache', error.message);
    }
  }

  /* Limpa o cache de uma tag específica.
  * Foi convencionado que a tag é o nome do método.
  */
  cleanCacheFromTag(tag: string): void {
    try {
      this.cacheService.removeByTag(tag);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCacheFromTag', error.message);
    }
  }

  /* Limpa o cache de uma chamada específica */
  cleanCacheFromKey(method: string, params: any[]): void {
    try {
      const key = this.wsTracker.getBaseKey(method, params);
      const wasDeleted = this.cacheService.remove(key);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCacheFromKey', error.message);
    }
  }

  /* Realiza um método post com expectativa de receber um XML em retorno.
   * Depois processa o XML e o transforma num objeto.
   */
  // tslint:disable-next-line: ban-types
  connect<T>(method: string, params: any[], firstTagName?: string, replayResult: boolean = true): Observable<T> {
    const key =
      this.wsTracker.getKey(params, method); // chamada get
    try {
      if (!this.canConnect(key)) {
        return of(null);
      }
      const data: string = this.createSOAPBody(method, params, this.TOKEN_STR);
      const headers = new HttpHeaders({
        'Content-Type': 'text/xml; charset="utf-8"',
        Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8',
        enctype: 'text/plain'
        // 'Authorization': `Bearer ${token}` Token será injetado no interceptor
      });
      this.wsTracker.addCall(key);
      // ATENÇÃO: SE HOUVER MAIS DE UM SUBSCRIBE PARA O RESULTADO, PODERÁ ENTRAR NO POST MAIS DE UMA VEZ
      const post$ = this.http
        .post(this.baseUrl, data, { headers, responseType: 'text' as 'text' })
        .pipe(
          this.setLog(key, method, params),
          this.parseXml<T>(key, method, params, firstTagName),
          this.error(key)
        );
      if (method == "ExecucaoTarefa_DeleteCadastro") {
        this.activityDetailSrv.cancelActivityDetailsByActivity(params[0].value)
      }
      post$.subscribe();

      return replayResult ?
        post$.pipe(
          // this.httpRetry<T>(3),
          publishReplay(), // Necessário, ou se houver mais de um subscribe, entrará repetidas vezes, poderia fazer um salvar ser duplicado, por exemplo.
          refCount(), // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers.
          this.error(key)
        ) : post$;
    } catch (error) {
      this.wsTracker.updateCall(key, EnWsTrackStatus.error);
      this.log.Registrar('Webservice', 'connect', error.message);
    }
    return of(null);
  }

  protected error = (key: string) => catchError((err: any, res: any) => {
    try {
      this.wsTracker.updateCall(key, EnWsTrackStatus.error);
      // throwError(err.json().error || 'Server error');
      this.log.Registrar('Webservice', 'error', err.message);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'error', error.message);
    }
    return of(res);
  })

  canConnect(key: string): boolean {
    try {
      if (this.CONNECTIONS_COUNT.hasOwnProperty(key)) {
        const item = this.CONNECTIONS_COUNT[key];
        item.count++;
        const res = item.count <= this.MAX_NUM_OF_SAME_CONNECTIONS;
        const diffSec = parseInt(this.global.dateDiff(item.dtStart, new Date(), 's'), 10);
        if (diffSec > this.TIME_INTERVAL_SEC) {
          item.count = 0;
          item.dtStart = new Date();
        }
        return res;
      } else {
        this.CONNECTIONS_COUNT[key] = { count: 1, dtStart: new Date() };
        return true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'canConnect', error.message);
    }
    return false;
  }

  /** Operador personalizado para um número pré-determinado de retentativas a cada delay. */
  // protected httpRetry = (maxRetry: number = 3, delayMs: number = 2000) =>
  //   retryWhen((e: Observable<T>) => e.pipe(
  //     scan((errorCount, err) => {
  //       if (errorCount >= maxRetry) {
  //         console.log(`Giving up after ${errorCount} tries`);
  //         throw err;
  //       }
  //       return errorCount + 1;
  //     }, 0),
  //     delay(delayMs)
  //   )
  //   )

  /* Operador personalizado para extrair o xml.
  * TODO: O ideal é separar o cache em outro operador.
  * Também fará atualização do cache.
  */
  protected parseXml = <T>(
    key: string,
    method: string,
    params: any[],
    firstTagName?: string) => mergeMap((res) => {
      return new Observable<T>(observer => {
        xml2js.parseString(
          res,
          {
            explicitArray: false,
            // Atenção, foi necessário adicionar essas opções manualmente na interface pois estão em lib/processors
            tagNameProcessors: [processors.stripPrefix],
            // Definido por não modificar para manter o padrão do webservice atual, especialmente devido
            // ao retorno dos dados processors.firstCharLowerCase]
            valueProcessors: [processors.parseNumbers, processors.parseBooleans]
          },
          (err, result) => {
            try {
              const obj = result.Envelope.Body[`${method}Response`][`${method}Result`];
              const nextObj = firstTagName ? obj[firstTagName] as T : obj as T;
              this.cacheService.set(this.wsTracker.getBaseKey(method, params), nextObj, method);
              this.wsTracker.updateCall(key, EnWsTrackStatus.completed);
              observer.next(nextObj as T);
            } catch (error) {
              this.wsTracker.updateCall(key, EnWsTrackStatus.error);
              this.log.Registrar(this.constructor.name, 'webservice.subscribe.parseString', error.message);
            }
            observer.complete();
          }
        );
      });
    })

  protected setLog = (key: string, method: string, params: any[]) =>
    tap((item: any) => {
      if (this.cnfJson.saveWebServiceLog && (!this.cnfJson.listOfMethodsToLog || this.cnfJson.listOfMethodsToLog.includes(method))) {
        const obj = { date: new Date(), key, method, params };
        const log = localStorage.getItem('WEBSERVICE_LOG');
        const parsed = log ? JSON.parse(log) : null;
        const jsonLog = log ? [...parsed, obj] : [obj];
        localStorage.setItem('WEBSERVICE_LOG', JSON.stringify(jsonLog));
      }
    })

  /* Torna o primeiro caracter como maiúscula. */
  firstCharLowerCase(str: string): string {
    try {
      return str.charAt(0).toLowerCase() + str.slice(1);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'firstCharLowerCase', error.message);
    }
    return str;
  }

  // FIXME: *** CRIAR UM MECANISMO PARA INJETAR O TOKEN OU NA API OU NO INTERCEPTOR ***
  /* Cria o envelope da mensagem SOAP. */
  protected createSOAPBody(methodName: string, params: any[], token: string): string {
    try {
      let strParams = '';
      for (const par of params) {
        strParams += par ? `<${par.name}>${par.value}</${par.name}>` : '';
      }
      // tslint:disable: max-line-length
      const bodyEnvelope = `<?xml version='1.0' encoding='utf-8'?>
                       <soap:Envelope xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema' xmlns:soap='http://schemas.xmlsoap.org/soap/envelope/'>
                        <soap:Body>
                          <${methodName} xmlns='GE/ws/'>
                            <token>${token}</token>
                            ${strParams}
                          </${methodName}>
                        </soap:Body>
                      </soap:Envelope>`;
      return bodyEnvelope;
    } catch (error) {
      this.log.Registrar('WebService', 'createSOAPBody', error.message);
    }
    return null;
  }


}
