<div class="grid">

  <div class="top">

    <button mat-icon-button class="btn btn-back" (click)="onBack($event)">
      <mat-icon>arrow_back</mat-icon> Voltar</button>

    <mat-form-field class="search">
      <input type="text" matInput placeholder="Procurar..." [formControl]="search" (keydown.esc)="onEsc($event)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

  </div>
  <div class="list" *ngIf="(groups$ | async) as groups; else loading">
    <ui-smart-list-calendar [@fadeAnimation]="'in'" [groups]="groups" [search]="search$ | async"
      (itemClick)="itemClick($event, enTheme, readOnly)" [imgUrl]="imgUrl" [enTheme]="enTheme">
    </ui-smart-list-calendar>

  </div>

  <button mat-fab class="btn-add" aria-label="Novo" (click)="addClick(enTheme)">
    <mat-icon>add</mat-icon>
  </button>

  <ng-template #loading>
  <ui-loading-spinner></ui-loading-spinner>
      <span>{{loadingString}} </span>
  </ng-template>
