import { ConfigStateService } from '@medlogic/shared/state-config';
import { EnActivityType } from '@medlogic/shared/gecore';
import { UnsubscribeOnDestroyAdapter, IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { EnMessageSeverity } from '@medlogic/shared/gecore';
import { IMessage } from '@medlogic/shared/gecore';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { NavigationService } from '../../../shared/service/navigation.service';
import { LibService } from '../../../shared/service/lib.service';
import { FormGroup } from '@angular/forms';
import { IBubble } from '../../../shared/interface/ibubble';
import { CalculatorService } from '../../../shared/service/calculator.service';
import { DadoDAL } from '../../../shared/model/dal/dado-dal';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { EnBubbleEvent } from '../../../shared/enum/en-bubble-event.enum';
import { IAtividade } from '../../../shared/interface/iatividade';
import { MatDialog } from '@angular/material/dialog';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
// Barra de botões da Atividade.
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.css']
})
export class ActionBarComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges {

  // tslint:disable: variable-name
  public _atividade: IAtividade;
  get atividade(): IAtividade {
    return this._atividade;
  }
  @Input()
  set atividade(v: IAtividade) {
    this._atividade = v;
    if (v) {
      this.hasConfirm = this.hasConfirmButton(this.activityType);
    }
  }

  // tslint:disable-next-line: variable-name
  private _saved: boolean;
  public get saved(): boolean {
    return this._saved;
  }
  @Input() public set saved(v: boolean) {
    this._saved = v;
    if (v) {
      this.showSaveMessage(true);
    }
  }

  @Input() componentes: IAtividadeComponenteDAL[];
  @Input() enTheme = EnTheme.default;
  @Input() tarefaNo: number;
  @Input() formErrors: any[];
  @Input() msgs: IMessage[] = []; // Preencha essa variável para que seja exibido uma div no rodapé.
  @Input() activityType: EnActivityType;
  @Input() saveInList: boolean;
  @Input() addToHistory = true;
  @Input() formGroup: FormGroup;
  @Input() isMobile: boolean;
  @Input() isLoading = false;
  @Input() isEdit = false

  //  saved: boolean;
  @Input() canShowSavedMessages: boolean;

  //#region movidos para a button bar
  // public get hasPrint(): boolean {
  //   if (this.config.showOnlySaveButton) {
  //     return false;
  //   }
  //   return this.atividade.ShowPrint;
  // }

  // public get textPrint(): string {
  //   return this.atividade.TextPrint;
  // }

  // public get hasAttachment(): boolean {
  //   if (this.config.showOnlySaveButton) {
  //     return false;
  //   }
  //   return this.atividade.ShowAttachment;
  // }

  // public get textAttachment(): string {
  //   return this.atividade.TextAttachment;
  // }
  //#endregion

  private _hasConfirm = false;
  public _hasDelete = false;
  public get hasConfirm(): boolean {
    return (this.atividade.ShowComplete || this.isListDetail()) && !this.config.isReadOnly;
  }
  public set hasConfirm(v: boolean) {
    this._hasConfirm = v;
  }

  public get textConfirm(): string {
    return this.msg.BUTTON_CONFIRM;
  }

  public get hasSave(): boolean {
    return this.atividade.ShowSave && !this.isListDetail() && !this.config.isReadOnly;
  }

  public get textSave(): string {
    return this.atividade.TextSave;
  }

  // FIXME: Atenção: o serviço getAtividadePorAtividadeNoProcessoNo
  // não está trazendo essa variável preenchida (mas funciona quando pega da AtividadeCadastro e não do processo).
  public get hasSaveAndNew(): boolean {
    if (this.cnfJson.showOnlySaveButton) {
      return false;
    }
    return this.atividade.ShowSaveAndNew && !this.isListDetail() && !this.config.isReadOnly;
  }

  public get textSaveAndNew(): string {
    return this.atividade.TextSaveAndNew || this.msg.BTN_SAVE_AND_NEW;
  }

  public get hasDuplicate(): boolean {
    if (this.cnfJson.showOnlySaveButton) {
      return false;
    }
    if (this.atividade.ShowDuplicate !== null) {
      return this.atividade.ShowDuplicate;
    }
    return this.hasSaveAndNew;
  }

  public get textDuplicate(): string {
    return this.msg.BTN_DUPLICATE;
  }

  // Essa lógica faz com que o estado mude mais de uma vez antes da renderização da página, o que gera erro.
  // public get hasDelete(): boolean {
  // 	return this.atividade.ShowSave && this.hasDeleteBtn() && !this.config.isReadOnly;
  // }

  // tslint:disable-next-line: variable-name
  // private _hasDelete: boolean;
  // public get hasDelete(): boolean {
  //   return this._hasDelete;
  // }
  // public set hasDelete(v: boolean) {
  //   this._hasDelete = v;
  // }

  // public get textDelete(): string {
  //   return this.msg.BUTTON_DELETE;
  // }

  attachmentCount = 1;

  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble: EventEmitter<IBubble> = new EventEmitter<IBubble>();
  onEventBubble($event: IBubble) {
    this.eventBubble.emit($event);
  }

  constructor(
    public config: ConfigStateService,
    public cnfJson: ConfigJsonService,
    protected log: LogService,
    protected dadoDAL: DadoDAL,
    protected global: GlobalService,
    protected calc: CalculatorService,
    protected lib: LibService,
    protected nav: NavigationService,
    protected matDialog: MatDialog,
    protected msg: MsgPtBR,
    protected snackBar: MatSnackBar,
    

  ) {
    super();
  }

  ngOnInit() {
    try {
      this.hasConfirm = this.atividade && this.atividade.ShowSave && this.isListDetail();
      console.log(this._atividade)
      // this.hasDelete = this.atividade && this.atividade.ShowSave && this.hasDeleteBtn() && !this.config.isReadOnly;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Checa se o botão de confirmação deve ou não aparecer. */
  protected hasConfirmButton(activityType: EnActivityType): boolean {
    try {
      if (this.cnfJson.showOnlySaveButton) {
        return false;
      }
      let hasConfirm = false;
      hasConfirm = hasConfirm || [EnActivityType.ProcessActivity].includes(activityType);
      hasConfirm = hasConfirm || this.isListDetail();
      hasConfirm = hasConfirm && this.atividade.ShowSave;
      return hasConfirm;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasConfirmButton', error.message);
    }
    return false;
  }

  ngOnChanges() {
    try {
      if (this.saved) {
        if (this.canShowSavedMessages) { this.showSaveMessage(true); }
        this.afterSaved();
        this.saved = false;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnChanges', error.message);
    }
  }

  /* Exibe uma mensagem, que desaparece automaticamente após um tempo.
  * Também previne que a mesma mensagem seja exibida novamente, ao mesmo tempo que a outra identica.
  */
  protected showMessage(msg: IMessage, interval: number = 1500): void {
    try {
      const prevMessage = this.msgs.filter(
        (f) => this.global.isEqual(f.detail, msg.detail) && this.global.isEqual(f.summary, msg.summary)
      );
      if (!prevMessage || prevMessage.length <= 0) {
        this.msgs.push(msg);
        if (this.msgs && this.msgs.length > 0) {
          this.snackBar.open(
            this.msgs.map(m => m.detail).join(' '),
            this.msgs[0].summary,
            {
              duration: interval,
              verticalPosition: 'top'
            }
          );
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showMessage', error.message);
    }
  }

  /* Mensagem de tentativa de salvamento */
  protected showSaveMessage(isSuccess: boolean): void {
    try {
      if (this.canShowSavedMessages) {
        if (isSuccess) {
          this.showMessage({
            severity: EnMessageSeverity.success,
            summary: this.msg.ALERT_CONFIRMATION_TITLE,
            detail: this.msg.ALERT_ACTIVITY_SAVED
          });
        } else {
          this.showMessage({
            severity: EnMessageSeverity.error,
            summary: this.msg.ALERT_WARNING_TITLE,
            detail: this.msg.ALERT_ACTIVITY_SAVE_FAILED
          });
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showSaveMessage', error.message);
    }
  }

  /** Avisa o controle que a mensagem já foi exibida, para zerar o saved. */
  protected afterSaved(): void {
    try {
      this.eventBubble.emit({
        $event: null,
        bubbleEvent: EnBubbleEvent.afterSavedMessage
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSaved', error.message);
    }
  }

  /* Identifica se a atividade atual é do tipo lista. */
  protected isListDetail(): boolean {
    try {
      const result = this.activityType === EnActivityType.ListDetail; // && (this.saveInList.toString() === 'true')) ||
      // (this.activityType != EnActivityType.ListDetail);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasSaveInList', error.message);
    }
    return false;
  }

  /* Resgata os valores inseridos no formGroup e armazena em um novo item.  */
  protected getValueFormGroup(newItem: any): any {
    try {
      if (!newItem) {
        newItem = {
          index: -1,
          label: ''
        };
      }
      const values = this.formGroup.getRawValue();
      for (const clm in values) {
        if (clm !== 'index') {
          if (this.lib.getVariavelNoFromId(clm) !== this.config.listIdVariableNo) {
            newItem[clm] = this.lib.getGEValue(values[clm]);
          }
          if (this.lib.getVariavelNoFromId(clm) === this.config.listTitleVariableNo) {
            newItem.label = values[clm];
          }
        }
      }
      return newItem;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValueFormGroup', error.message);
    }
  }

  /* Botão de confirmação, que fará o historyBack, e salvará os dados apenas se o controle estiver configurado
  * para salvar os dados em Cadastro.
  * Na verdade, a AtividadeCadastro (list) está preparada para alterar o objeto gridItem, na memória,
  * que será utilizado para devolução dos dados à atividade principal, que chamou essa atividade.
  */
  onConfirm($event: any, saveInList: any): void {
    try {
      this.isLoading = true;
      // let ItemForm = this.getValueFormGroup(null);
      const backAfterComplete = this.activityType !== EnActivityType.ListDetail;
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.activityComplete,
        params: { backAfterComplete }
      } as IBubble);
      // Não pode emitir o historyback, pois, haverá validação do formulário e se não for válido, não retornará.
      // this.eventBubble.emit(<IBubble>{
      // 	$event,
      // 	bubbleEvent: EnBubbleEvent.historyBack,
      // 	params: {}
      // });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onConfirm', error.message);
    }
  }

  /* Além de salvar, cria um novo registro, com os dados em branco.
  * Nessa opção, é obrigatório a marcação de SaveInList, pois, caso contrário,
  * os dados não seriam salvos (na confirmação simples eles somente são transportados para
  * a tela anterior, onde devem ser salvos).
  */
  onSaveAndNew($event: any): void {
    try {
      this.isLoading = true;
      // New
      const newItem = {
        index: -1,
        label: ''
      };
      const ano = this.componentes[0].AtividadeNo;
      // this.config.setGridItem(ano, newItem);
      this.nav.preventAddToHistory = true;
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.listSaveAndNew,
        params: { ano, saveInList: true, addToHistory: this.addToHistory }
      } as IBubble);
      this.resetTab();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSaveAndNew', error.message);
    }
  }

  /* Força a tab para o primeiro item.
  * Se isso não for feito, a primeira aba poder não estar selecionada,
  * e a tela fica que branco.
  */
  protected resetTab(): void {
    try {
      this.eventBubble.emit({
        bubbleEvent: EnBubbleEvent.tabChange,
        params: { tabIndex: 0 }
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'resetTab', error.message);
    }
  }

  /* Similar ao Salvar e novo, no entanto, copia os dados da atividade atual para a próxima, exceto index. */
  onDuplicate($event: any): void {
    try {
      this.isLoading = true;
      // New
      const ano = this.componentes[0].AtividadeNo;
      const newItem = this.getValueFormGroup(null);
      // this.config.setGridItem(ano, newItem);
      // Essa propriedade deverá ser atualizada, para que, ao recarregar a atividade os respectivos valores tenham sido copiados.
      this.config.setDefaultFormControls(ano, newItem);
      this.nav.preventAddToHistory = true;
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.listDuplicate,
        params: { ano, saveInList: true, addToHistory: this.addToHistory }
      } as IBubble);
      this.resetTab();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDuplicate', error.message);
    }
  }

  /* Exibe mensagem de confirmação e, se confirmado, exclui o item do Cadastro permanentemente. */
   onDelete($event: any): void {
     try {
       this.isLoading = true;
       const message = {
         firstButtonLabel: 'Não',
         title: 'Confirmação',
         icon: 'fa-times',
         text: `Você tem certeza que quer excluir esse item? Essa exclusão NÃO poderá ser desfeita!`,
         acceptFunc: () => {
           try {
  //           // TODO: Encontrar uma forma de limpar o cache
            this.eventBubble.emit({
              $event,
              bubbleEvent: EnBubbleEvent.listDelete,
              params: {
                ano: this.config.ModeloAtividadeNo,
                ono: this.config.OcorrenciaNo.value,
                pno: this.atividade.ProcessoNo
              }
            } as IBubble);
            // Retorna para o grid
             this.eventBubble.emit({
               $event,
               bubbleEvent: EnBubbleEvent.gotoErpGrip
             } as IBubble);
             window.history.go(-1);
           } catch (error) {
             this.log.Registrar(this.constructor.name, 'onDelete.acceptFunc', error.message);
           }
         }
       };
       this.onAlertDialog($event, message, true);
     } catch (error) {
       this.log.Registrar(this.constructor.name, 'onDelete', error.message);
     }
   }

  /* Emite um evento para a Atividade View para exibição de uma janela de Alerta. */
  protected onAlertDialog($event: any, message: any, hasConfirmButton: boolean): void {
    try {
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.alertDialog,
        params: { message, hasConfirmButton }
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAlertDialog', error.message);
    }
  }

  /* Clique no botão anexos. */
  onAttachment($event: any): void {
    try {
      // TODO: Mudar para > 0 depois de implementado o retorno do número de arquivos
      const url = this.cnfJson.attachmentUrl(
        this.config.OcorrenciaNo.value,
        this.config.ModeloAtividadeNo,
        this.config.usuarioLogadoNo,
        '60vh'
      );
      if (this.attachmentCount >= 0) {
        this.eventBubble.emit({
          $event,
          bubbleEvent: EnBubbleEvent.openAttachments,
          params: {
            url,
            ono: this.config.OcorrenciaNo.value,
            ano: this.config.ModeloAtividadeNo,
            tno: this.config.TarefaNo,
            width: '100%',
            height: '100vh'
          }
        } as IBubble);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAttachment', error.message);
    }
  }

  /* Clique no botão imprimir.  */
  // onPrint($event: any): void {
  //   try {
  //     let var1 = '';
  //     let var2 = '';
  //     try {
  //       var1 = this.componentes.filter(f => f.VariavelNo === this.atividade.CalcVariavelIdentificacao1No)[0].ValorTexto;
  //       var2 = this.componentes.filter(f => f.VariavelNo === this.atividade.CalcVariavelIdentificacao2No)[0].ValorTexto;
  //     } catch { }
  //     this.imprimir(
  //       this.config.OcorrenciaNo.value,
  //       this.config.usuarioLogadoNo,
  //       this.config.ModeloAtividadeNo,
  //       -1,
  //       this.config.TarefaNo,
  //       this.config.processoNo,
  //       var1,
  //       var2,
  //       this.atividade.UrlPrint,
  //       '0',
  //       this.componentes,
  //     );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'onPrint', error.message);
  //   }
  // }

  /* Irá abrir uma modal e renderizar uma url da intranet em iframe. */
  // protected imprimir(
  //   ocorrenciaNo: number,
  //   usuarioLogadoNo: number,
  //   atividadeNo: number,
  //   atividadeMultiplaNo: number,
  //   tarefaNo: number,
  //   processoNo: number,
  //   Complemento1: string,
  //   Complemento2: string,
  //   urlPrint: string,
  //   TabIndex: string,
  //   componentes: Array<IAtividadeComponenteDAL>,
  // ): void {
  //   try {
  //     const urlRelatorios = this.montarUrlRelatorio(
  //       urlPrint.indexOf('?') === -1 ? '?' : '&',
  //       ocorrenciaNo,
  //       usuarioLogadoNo,
  //       atividadeNo,
  //       atividadeMultiplaNo,
  //       tarefaNo,
  //       processoNo,
  //       Complemento1,
  //       Complemento2,
  //       urlPrint
  //     );
  //     const bottomSheet = this.bottomSheet
  //       .open(BottomSheetComponent, {
  //         data: { urls: urlRelatorios }
  //       });
  //     this.subs.sink = bottomSheet.afterDismissed()
  //       .subscribe(selectedItem => {
  //         const width = '100vw';
  //         const height = '90vh';
  //         const index = selectedItem ? urlRelatorios.findIndex(f => this.global.isEqual(f.title, selectedItem.title)) : -1;
  //         if (index >= 0) {
  //           const replaceVariables = componentes.map(m => ({ key: m.VariavelNo, value: (m.Valor || '').toString() } as IKeyValue));
  //           const fileName = `${urlRelatorios[index].title}.docx`;
  //           const ctrBarcode = componentes.filter(f => f.Valor).find(f => f.Valor.toString().toUpperCase().includes('BARCODE'));
  //           const ctrQrcode = componentes.filter(f => f.Valor).find(f => f.Valor.toString().toUpperCase().includes('QRCODE'));
  //           const barcodeNum = ctrBarcode ? ctrBarcode.Valor.toString().split(';')[1] : null;
  //           const arrQr = ctrQrcode.Valor.toString().split(';');
  //           const qrcodeUrl = ctrQrcode ? this.global.urlJoin([arrQr[1], barcodeNum, arrQr[2], arrQr[3]]) : null;
  //           const url = urlRelatorios[index].url.split('?')[0];
  //           const dialogRef = this.matDialog.open(DocGenerateDialogComponent, {
  //             width,
  //             height,
  //             data: { url, fileName, barcodeNum, qrcodeUrl, replaceVariables }
  //           });
  //           this.subs.sink = dialogRef.afterClosed()
  //             .subscribe(result => {
  //               this.eventBubble.emit({
  //                 $event: null,
  //                 bubbleEvent: EnBubbleEvent.afterPrint
  //               } as IBubble);
  //             });
  //         }
  //       });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'method', error.message);
  //   }
  // }


  // /* Define a url do relatório baseado em parâmetros. */
  // protected montarUrlRelatorio(
  //   tipoQuery: any,
  //   ocorrenciaNo: number,
  //   usuarioLogadoNo: number,
  //   atividadeNo: number,
  //   atividadeMultiplaNo: number,
  //   tarefaNo: number,
  //   processoNo: number,
  //   Complemento1: string,
  //   Complemento2: string,
  //   UrlPrint: string,
  // ): Array<IUrl> {
  //   try {
  //     const lstUrlPrint = UrlPrint.split(';');
  //     // Aba$NomeRelatorio$Url;Aba$NomeRelatorio$Url - Para Permitir configurar varios relatórios por aba em uma mesma atividade
  //     if (lstUrlPrint.length > 1) {
  //       // tslint:disable-next-line: prefer-for-of
  //       const lst = lstUrlPrint
  //         .map(item => {
  //           const lstUrlPrintSplit = item.split('$');
  //           const title = lstUrlPrintSplit[1];
  //           UrlPrint = lstUrlPrintSplit[2];
  //           tipoQuery = UrlPrint.indexOf('?') === -1 ? '?' : '&';
  //           UrlPrint = `${UrlPrint}${tipoQuery}`;
  //           const token = this.config.baseUsuarioToken;
  //           let urlRelatorio = '';
  //           if (UrlPrint.indexOf('?') < 0) {
  //             // tslint:disable: max-line-length
  //             urlRelatorio = `${UrlPrint}?ocrNo=${ocorrenciaNo}
  // &usoNo=${usuarioLogadoNo}&atvNo=${atividadeNo}&atvmNo=${atividadeMultiplaNo}&trfNo=${tarefaNo}
  // &proNo=${processoNo}&cpm1=${Complemento1}&cpm2=${Complemento2}&Token=${token}`;
  //           } else {
  //             urlRelatorio = `${UrlPrint}ocrNo=${ocorrenciaNo}
  // &usoNo=${usuarioLogadoNo}&atvNo=${atividadeNo}&atvmNo=
  // ${atividadeMultiplaNo}&trfNo=${tarefaNo}&proNo=${processoNo}&cpm1=${Complemento1}&cpm2=${Complemento2}&Token=${token}`;
  //           }
  //           return { title, url: urlRelatorio } as IUrl;
  //         });
  //       return this.global.distinctBy<IUrl>(lst, 'title');
  //     } else {
  //       return [{ title: UrlPrint, url: UrlPrint } as IUrl];
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'montarUrlRelatorio', error.message);
  //   }
  //   return null;
  // }


  // TODO: também deve ser validado se o usuário possui permissão para excluir o item do Cadastro.
  /* Se a Ocorrência não tiver sido criada, não exibir o botão de exclusão.
  * Há ainda uma validação das configurações do módulo, no config.json.
  */
  // protected hasDeleteBtn(): boolean {
  //   try {
  //     return true;
  //     //   if (this.config.showOnlySaveButton) {
  //     //     return false;
  //     //   }
  //     //   if (this.atividade.ShowDelete !== null) {
  //     //     return this.atividade.ShowDelete;
  //     //   }
  //     //   const modAdmissao = this.config.modules.find((f) => f.name === 'ADMISSÃO');
  //     //   const blDelete = modAdmissao && modAdmissao.canDelete !== undefined ? modAdmissao.canDelete : true;
  //     //   return this.config.OcorrenciaNo.value > 0 && blDelete;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'hasDeleteBtn', error.message);
  //   }
  //   return false;
  // }


  /** Verifica se o tema black deve ser aplicado. */
  isBlack(): boolean {
    try {
      return this.enTheme === EnTheme.black;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isBlack', error.message);
    }
    return false;
  }


}

